import { useMemo, useCallback } from 'react';

import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';

import { useAuth } from '../../../auth/AuthProvider';
import { AuthState } from '../../../auth/authMachine';
import { Routes } from '../../../constants/routes';
import { ABBVIE_URLS } from '../../../constants/urls';

const useFooterTrackingEvents = () => {
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();
  const isLoggedIn = authState.matches(AuthState.isLoggedIn);

  const eventAttribtes = useMemo(
    () => ({
      action_source: 'consumer web',
      invoked_by: 'consumer',
      user_type: 'consumer',
      event_type: 'engagement',
      user_status: isLoggedIn ? 'logged_in' : 'logged_out',
    }),
    [isLoggedIn]
  );

  const trackLinkClick = useCallback(
    ({ url, text }: { url?: string; text?: string }) => {
      trackConsumer()?.siteNavigated({
        event: {
          ...eventAttribtes,
          activator: `Footer ${text ?? ''} Link`,
          explainer: 'Tracking user engagement with footer links',
          event_type: 'engagement',
        },
        site_type: 'consumer web',
        navigation_type: 'footer navigation',
        event_source_url: window.location.href,
        text: `${text ?? ''}`,
        url: `${url ?? ''}`,
      });
    },
    [trackConsumer, eventAttribtes]
  );

  const trackCookiesClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Footer Cookies Button',
        explainer: 'Tracking user engagement with footer cookie settings',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Allē',
    });
  }, [trackConsumer, eventAttribtes]);

  const trackPrivacyClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Footer Privacy Link',
        explainer: 'Tracking user engagement with footer privacy settings',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Your Privacy Choices',
      url: Routes.settingsCommunication,
    });
  }, [trackConsumer, eventAttribtes]);

  const trackPrivacyModalLoginClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Privacy Modal Sign In Link',
        explainer: 'Tracking user engagement with privacy choices modal',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      url: Routes.login,
      text: 'Sign In',
    });
  }, [trackConsumer, eventAttribtes]);

  const trackPrivacyModalCookiesClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Privacy Modal Cookies Button',
        explainer: 'Tracking user engagement with privacy choices modal',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Cookie preferences',
    });
  }, [trackConsumer, eventAttribtes]);

  const trackPrivacyModalRightsClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Privacy Modal Abbvie Rights Link',
        explainer: 'Tracking user engagement with privacy choices modal',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Abbvie Data Subject Rights Tool',
      url: ABBVIE_URLS.dataSubjectRightsRequestForm,
    });
  }, [trackConsumer, eventAttribtes]);

  return {
    trackLinkClick,
    trackCookiesClick,
    trackPrivacyClick,
    trackPrivacyModalLoginClick,
    trackPrivacyModalCookiesClick,
    trackPrivacyModalRightsClick,
  };
};

export { useFooterTrackingEvents };
