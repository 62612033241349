import React from 'react';

const FooterNavPrivacyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="ccpa-opt-out-title"
    width="100%"
    height="100%"
  >
    <title id="ccpa-opt-out-title">
      California Consumer Privacy Act (CCPA) Opt-Out Icon
    </title>
    <g clipPath="url(#clip-path-ccpa-opt-out)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9 12.8h6.8l3.1-11.6H7.9C4.7 1.2 2.1 3.8 2.1 7c0 3.2 2.6 5.8 5.8 5.8z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1 0H7.9C4 0 .9 3.1.9 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.9c-3.2 0-5.8-2.6-5.8-5.8z"
        fill="#C08676"
      />
      <path
        d="M25.1 4c.2.2.2.6 0 .8L23 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2L20 10c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L21.3 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L24.3 4c.2-.2.6-.2.8 0z"
        fill="#fff"
      />
      <path
        d="M13.2 4.1c.2.2.3.6.1.8L9.1 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.9 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l1.8 1.7 3.8-4.5c.2-.2.6-.2.9 0z"
        fill="#C08676"
      />
    </g>
    <defs>
      <clipPath id="clip-path-ccpa-opt-out">
        <path fill="#fff" transform="translate(.5)" d="M0 0h30v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { FooterNavPrivacyIcon };
