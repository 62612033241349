import { useContext, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useAlleTreatments } from '@packages/core-split-feature-flag/src/useAlleTreatments';
import { NotificationContext } from '@packages/deprecated-component-library/src/notificationBar/notificationContext';
import { caFeatureKeys } from '@packages/core-split-feature-flag/src/consumer';

const useRouteBasedNotificationBanner = () => {
  const location = useLocation();
  const { addNotification } = useContext(NotificationContext);
  const splitTreatments = useAlleTreatments({
    splitNames: [caFeatureKeys.routeBasedNotificationBanner],
  });

  const routeBasedNotificationBanner =
    splitTreatments[caFeatureKeys.routeBasedNotificationBanner];

  useEffect(() => {
    if (routeBasedNotificationBanner.treatment === 'on') {
      const config = routeBasedNotificationBanner?.config
        ? JSON.parse(routeBasedNotificationBanner?.config)
        : {};

      const routeWithNotification = Object.keys(config).find((route) => {
        const match = matchPath(location.pathname, route);
        return match?.isExact;
      });

      if (routeWithNotification && config[routeWithNotification]) {
        addNotification({
          id: 'consumer-notification',
          text: config[routeWithNotification],
          variant: 'general',
        });
      }
    }
  }, [location.pathname, routeBasedNotificationBanner, addNotification]);
};

export { useRouteBasedNotificationBanner };
